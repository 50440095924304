<template>
  <div class="Detail">
    <div class="morework">
      <div class="morework__div__background">
        <div class="image__container">
          <img :src="require(`@/assets/home/works/${image}`)" />
        </div>
        <div class="line__container"></div>
        <div class="text__container">
          <h3>{{ title }}</h3>
        </div>
      </div>
    </div>
    <div class="information">
      <div class="information_contents">
        <p>{{ descriptions }}</p>
        <div v-if="title === 'Web portals development'">
          <h1>Front-end design</h1>
          <p>
            Front end design insists on the creative and personalisation of your
            website. It is the look and feel of the website where your clients
            will interact with your business. Our graphics and visual design
            specialists create the web front end design. After a team
            brainstorming session we design a concept that is especially
            tailored to you, our client. From there, designers work on the look
            of the website by designing template mockups that visually show the
            whole website. These mockups are then presented to our clients for
            approval for the next step to a good website.
          </p>
          <h1>Back end development</h1>
          <p>
            After approval of the client, the second step consists of designing
            the back end. The backend of a web portal might include a Content
            Management System, a Client Relationship Management System (CRM) to
            know and provide better service to your clients or an ecommerce
            platform where you sell your goods and services and a dashboard to
            manage orders.
          </p>
        </div>

        <!-- <p>
          The standard chunk of Lorem Ipsum used since the 1500s is reproduced
          below for those interested. Sections 1.10.32 and 1.10.33 from "de
          Finibus Bonorum et Malorum" by Cicero are also reproduced in their
          exact original form, accompanied by English versions from the 1914
          translation by H. Rackham.
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
// import LayoutDefault from "../layouts/LayoutDefault.vue";
export default {
  name: `Details`,
  props: ["descriptions", "title", "image"],
  components: {},
  created() {
    // this.$emit(`update:layout`, LayoutDefault);
    console.log(this.image);
    if (!this.title) {
      this.title = "DIGITAL MARKETING";
      this, (this.image = "digitalmarketing.jpg");
      this.descriptions =
        "For your website to achieve your business objectives, it needs to be found online by existing customers as well as potential new customers. eVolve can help you achieve this using many techniques including SEO - Search Engine Optimisation, SMM - Social Media Marketing, SMS/Email Marketing.";
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss">
.morework {
  margin-top: 6rem;
  &__div__background {
    background: $color-white;
    width: 100%;
    height: 45rem;
    box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
    -webkit-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    position: relative;
    padding: 3rem 6rem;
    margin-top: 3rem;
    @include respond(phone) {
      flex-direction: column;
      // display: none;
      padding: 0;
      height: fit-content;
    }
    .image__container {
      height: 100%;
      width: 45%;
      box-shadow: 2px 17px 49px -15px rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 2px 17px 49px -15px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 2px 17px 49px -15px rgba(0, 0, 0, 0.5);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include respond(phone) {
        height: 30rem;
        width: 100%;
      }
      img {
        max-width: 100%;
        min-width: 100%;
        max-height: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
    .text__container {
      width: 45%;
      height: 35rem;
      background: #01b3a3;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6rem;
      justify-content: center;
      box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      -webkit-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      -moz-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
      @include respond(phone) {
        width: 100%;
        padding: 30px 0;
      }
      h3 {
        font-size: 2.5rem;
        font-weight: bolder;
        width: 25rem;
        text-transform: uppercase;
        font-size: 4rem;
        @include respond(phone) {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
.information {
  &_contents {
    background: $color-primary;
    box-shadow: 0px 20px 20px 10px rgba(0, 0, 0, 0.22);
    height: fit-content;
    padding: 7rem;
    align-items: center;
    margin-top: 10rem;
    @include respond(phone) {
      padding: 3rem 1rem;
    }
    h1,
    p {
      width: 65%;
      font-size: 1.3rem;
      margin: auto;
      color: #d4d4d4;
      &:last-child {
        margin-top: 3rem;
      }
      @include respond(phone) {
        width: 75%;
      }
    }
    h1 {
      font-size: 2rem;
      padding: 1.5rem 0;
      color: #020202;
    }
  }
}
</style>